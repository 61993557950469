import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FactoresService {

  //url='http://localhost/dataMining/';
  url='https://apivendor.tu7.cl/'; 

  constructor(private http: HttpClient) { }

  recuperarTodos(edad) {
    return this.http.get(`${this.url}getFactores.php?edad=`+edad); 
  }
}
