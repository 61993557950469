       <!--<div class="sidebar-shop">-->
          <div class="card">
             <div class="card-body"> 
                <div class="brands">
                   <div class="alert alert-primary" role="alert">
                      <div class="alert-body text-center">
                        Elige tu(s) prestador(es) de preferencia <i data-feather="info" data-toggle="popover" data-content="Según la zona de cobertura elegida, se desplegará un listado de prestadores. Debes elegir tu prestador de preferencia y el sistema te mostrará todos los planes asociados a dicha selección." data-trigger="hover" data-original-title="Elige tu(s) prestador(es) de preferencia" class="font-medium-3 text-muted"></i>
                      </div>
                   </div>

                  <form id="PrestadoresList"> 
                     <ul class="list-unstyled brand-list"> 
                        <li *ngFor="let Lprestador of Lprestadores" class="" id="fila-{{Lprestador.id}}-{{Lprestador.zonas}}">
                           <div class="custom-control custom-checkbox">
                              <input class="form-check-input me-2" type="checkbox" value="" id="prestador-{{Lprestador.id}}" (click)="filtroPrestador($event.target)">

                              <!--<input type="checkbox" class="custom-control-input" id="prestador-{{Lprestador.id}}" value="{{ Lprestador.nombre }}" name="prestador-{{ Lprestador.id }}" (change)="filtroPrestador($event.target)">-->
                              <!--<label class="custom-control-label small" for="prestador-{{ Lprestador.id }}">{{ Lprestador.nombre }}</label>-->
                              <label class="form-check-label" id="prestadorname-{{Lprestador.id}}" for="prestador-{{Lprestador.id}}">{{Lprestador.nombre}}</label>
                              <label class="form-check-label d-none" id="prestadorkey-{{Lprestador.id}}" for="prestador-{{Lprestador.id}}">{{Lprestador.clave}}</label>
                           </div>
                       </li>
                     </ul>
                  </form>
  

                   <!--<select (change)="filtroPrestador()" class="select2 form-control small" [(ngModel)]="selected">
                   <option [value]="Lprestador.id" *ngFor="let Lprestador of Lprestadores">{{Lprestador.nombre}}</option>
                    </select>-->
                </div>
             </div>
          </div>
       <!--</div>-->
