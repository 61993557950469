import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IsapresService {

  //url='http://localhost/dataMining/';
  url='https://apivendor.tu7.cl/'; 

  constructor(private http: HttpClient) { }

  recuperarTodos() { 
    //console.log(this.url+'getIsapres.php');
    return this.http.get(this.url+'getIsapres.php');
  }

}
