import { Component } from '@angular/core';
import { AppPlanes } from './app.planes';

@Component({
  selector: 'app-planes-search',
  templateUrl: './app.planes.search.html'
})

export class AppPlanesSearch {
  title = 'app-planes-search'; 

  constructor(private planesApp: AppPlanes) {}

  setSearchText() {
    let tagSerach = (<HTMLInputElement>document.getElementById('plan-search')).value; 
    this.planesApp.setSearchText(tagSerach);
    return false;
	}

  clearSearchText() {
    (<HTMLInputElement>document.getElementById('plan-search')).value = '';
    this.planesApp.setSearchText('');
    return false;
  }

}
