      <!--<div class="sidebar-shop">-->
         <div class="card">
            <div class="card-body">
               <div class="brands">
                  <div class="alert alert-primary" role="alert"> 
                     <div class="alert-body text-center">
                        Elige tu(s) zona(s) <br>de cobertura <i data-feather="info" data-toggle="popover" data-content="Selecciona la zona de cobertura de más cercana a tu lugar de trabajo o residencia." data-trigger="hover" data-original-title="Zona de cobertura" class="font-medium-3 text-muted"></i>
                     </div>
                  </div>
                  <form id="ZonasList">
                  <ul class="list-unstyled brand-list"> 
                     <li *ngFor="let Lzona of Lzonas">
                        <div class="custom-control custom-checkbox">
                           <input type="checkbox" class="custom-control-input" id="zona-{{Lzona.id}}" name="zona-{{Lzona.id}}" (change)="filtroZona($event.target)">
                           <label class="custom-control-label font-11" for="zona-{{ Lzona.id }}">{{ Lzona.nombre }}</label>
                        </div>

                        <div id="soloZonaID-{{ Lzona.id }}" class="demo-spacing-0 text-center" style="float: right; width: 60px; cursor: pointer;">
                           <div class="alert alert-primary small" role="alert">
                              <div class="alert-body" class="" (mouseover)="showsolozona(Lzona.id)" (mouseout)="showplanessolozona(Lzona.id)"><span id="showplaneszonasolo-{{ Lzona.id }}" class="">{{ Lzona.planes }}</span> <span (click)="planesApp.soloZonaClick(Lzona.id) " id="showSolozona-{{ Lzona.id }}" class="hidden text-danger">SOLO</span> </div>
                           </div>
                        </div>
                        <div id="todoZonaID-{{ Lzona.id }}" class="demo-spacing-0 text-center hidden" style="float: right; width: 60px; cursor: pointer;">
                          <div class="alert alert-primary small" role="alert">
                             <div class="alert-body" class="" (mouseover)="showtodozona(Lzona.id)" (mouseout)="showplanestodozona(Lzona.id)"><span id="showplaneszonatodo-{{ Lzona.id }}" class="">{{ Lzona.planes }}</span> <span (click)="planesApp.todoZonaClick(Lzona.id) " id="showTodozona-{{ Lzona.id }}" class="hidden text-danger">TODOS</span> </div>
                          </div>
                       </div>
                     </li>
                  </ul>
                  </form>
               </div>
            </div>
         </div>
      <!--</div>-->
