import { Component } from '@angular/core';
//import { AppPlanes } from './app.planes';

@Component({
  selector: 'app-filtros',
  templateUrl: './app.filtro.html'
})

export class AppFiltros {


  constructor(
     ) {}

    ngOnInit() {
      //this.planesApp.recuperarUF();
    }
  

}