      <!--<div class="sidebar-shop">-->
         <div class="card">
            <div class="card-body"> 
               <div class="brands">
                  <div class="alert alert-primary" role="alert"> 
                     <div class="alert-body text-center">
                        Elige tu(s) Isapre(s) de preferencia <i data-feather="info" data-toggle="popover" data-content="Puedes seleccionar una o varias isapres y así podrás revisar y comparar las ofertas que cada compañía ofrece." data-trigger="hover" data-original-title="Isapres" class="font-medium-3 text-muted"></i> 
                     </div>
                  </div>
                  <form id="IsapresList">
                  <ul class="list-unstyled brand-list">
                     <li *ngFor="let Lisapre of Lisapres">
                        <div class="custom-control custom-checkbox">
                           <input type="checkbox" class="custom-control-input" id="isapre-{{ Lisapre.id }}"  name="isapre-{{ Lisapre.id }}" checked (change)="filtroIsapres()">
                           <label class="custom-control-label" for="isapre-{{ Lisapre.id }}">{{ Lisapre.nombre }}</label>
                        </div>


                        <div id="soloIsapreID-{{ Lisapre.id }}" class="demo-spacing-0 text-center" style="float: right; width: 60px; cursor: pointer;">
                           <div class="alert alert-primary small" role="alert">
                              <div class="alert-body" class="" (mouseover)="showsoloisapre(Lisapre.id)" (mouseout)="showplanessoloisapre(Lisapre.id)"><span id="showplanesisapresolo-{{ Lisapre.id }}" class="">{{ Lisapre.planes }}</span> <span (click)="planesApp.soloIsapreClick(Lisapre.id) " id="showSoloisapre-{{ Lisapre.id }}" class="hidden text-danger">SOLO</span> </div>
                           </div>
                        </div>
                        <div id="todoIsapreID-{{ Lisapre.id }}" class="demo-spacing-0 text-center hidden" style="float: right; width: 60px; cursor: pointer;">
                          <div class="alert alert-primary small" role="alert">
                             <div class="alert-body" class="" (mouseover)="showtodoisapre(Lisapre.id)" (mouseout)="showplanestodoisapre(Lisapre.id)"><span id="showplanesisapretodo-{{ Lisapre.id }}" class="">{{ Lisapre.planes }}</span> <span (click)="planesApp.todoIsapreClick(Lisapre.id) " id="showTodoisapre-{{ Lisapre.id }}" class="hidden text-danger">TODOS</span> </div>
                          </div>
                       </div>  



                        <!--<div (click) = "callIsapreSolo()" ng-mouseover="expression" class="demo-spacing-0 text-center" style="float: right; width: 60px; cursor: pointer;">
                           <div class="alert alert-primary small" role="alert">
                              <div class="alert-body" class="" (mouseover)="showSoloIsapre(Lisapre.id)" (mouseout)="showPlanIsapre(Lisapre.id)"><span id="showPlanesIsapre-{{ Lisapre.id }}" class="">{{ Lisapre.planes }}</span> <span (click)="planesApp.soloIsapreClick(Lisapre.id) " id="showSoloIsapre-{{ Lisapre.id }}" class="hidden text-danger">{{ Solo }}</span> </div>
                           </div>
                        </div>-->
                     </li>


                     <li>

                        <div class="alert alert-primary btn-block" role="alert">
                           <div class="alert-body">Total Planes : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span id="numPlanes" class="right"></span></div>
                        </div>
                        

                     </li>




                  </ul>
               </form>
               </div>
            </div>
         </div>
      <!--</div>-->
