import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';
import { AppFiltros } from './component/app.filtro';
import { FilterPeople } from './component/filter.people';
import { FilterIsapres } from './component/filter.isapres';
import { FilterPrestador } from './component/filter.prestador';
import { FilterAmount } from './component/filter.amount';
import { FilterZona } from './component/filter.zona';
import { FilterTipo } from './component/filter.tipo';
import { AppPlanes } from './component/app.planes';
import { AppPlanesHeader } from './component/app.planes.header';
import { AppPlanesSearch } from './component/app.planes.search';
import { NgxWhastappButtonModule } from "ngx-whatsapp-button";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'; 

@NgModule({
  declarations: [
    AppComponent,
    FilterPeople,
    FilterIsapres,
    FilterAmount,
    FilterZona,
    FilterTipo,
    FilterPrestador,
    AppPlanes,
    AppPlanesHeader,
    AppPlanesSearch,
    AppFiltros
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxWhastappButtonModule,
    NgbModule
  ],
  providers: [AppPlanes, AppPlanesSearch, FilterPeople, FilterPrestador],
  bootstrap: [AppComponent]
})
export class AppModule { }
