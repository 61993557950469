import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PrestadorService {

  //url='http://localhost/dataMining/';
  url='https://apivendor.tu7.cl/';
  basePRELIS = 'getPrestadores.php'; 

  constructor(private http: HttpClient) { }

  recuperarTodos(param) { 
    //console.log('servicio : '+`${this.url}getPrestadores.php?params=`+param);
    return this.http.get(`${this.url}getPrestadores.php?params=`+param);  
  }

  listaPrestadores(zona: string) {
    //console.log(this.url+this.basePRELIS+'?params='+zona);
    return this.http.get(this.url+this.basePRELIS+'?params='+zona);
  }


}
