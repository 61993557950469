import { Component, HostListener } from '@angular/core';
import { AppPlanes } from './app.planes';
import { UfService } from '../uf.service';

declare var $: any;

@Component({
  selector: 'filter-amount',
  templateUrl: './filter.amount.html'
})

export class FilterAmount {
  title = 'filter-amount';


  public valorUF = null;
    
  constructor(private appPlanes: AppPlanes, private ufServicio: UfService) {}  

  ngOnInit() {
    //this.recuperarUF(); 
  }

  recuperarUF() {
    //this.ufServicio.recuperarUF().subscribe(result => { this.valorUF = result;  $("#UFvalor").val(result); } );
    //$("#UFvalor").val();
    //console.log('UFvalor : '+$("#UFvalor").val());
    //$("#UFvalor").val("29,482.36");
    //this.ufServicio.recuperarUF().subscribe(result => { this.valorUF = result; }, error => console.log('error!!! : '+error) );
    //console.log('Listo!');
  }

  filtroMontos() {
    //console.log('Estoy en filtro montos!');
  }

  filtroAmount(tipo: any) {
    //console.log('change');
    //let numeralfrom = (<HTMLInputElement>document.getElementById("numeralfrom")).value;
    //let numeralto = (<HTMLInputElement>document.getElementById("numeralto")).value;
    //console.log('numeralfrom : '+numeralfrom);
    //console.log('numeralto : '+numeralto);
    this.appPlanes.SendFilterPlanes(tipo);
  }

}
