<div id="sidebar" class="sidebar-left" style="z-index: 10">
    <div class="sidebar">
        <div class="sidebar-shop">
            <filter-people></filter-people>
            <filter-isapres></filter-isapres>
            <filter-amount></filter-amount>
            <filter-tipo></filter-tipo> 
            <filter-zona></filter-zona>
            <!--<filter-prestador></filter-prestador>-->
        </div>
    </div>
 </div>
