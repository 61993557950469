import { Component, OnInit, NgZone, ChangeDetectorRef, Input, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import { PrestadorService } from '../prestador.service';
import { Subject } from 'rxjs';
import { Routes, RouterModule } from '@angular/router';
import { AppPlanes } from './app.planes';

declare var $: any;

@Component({
  selector: 'filter-prestador',
  templateUrl: './filter.prestador.html'
})

export class FilterPrestador implements OnInit { 

   
  Lprestadores: any = [];
  
  selected: number = 0;

  //Lprestadores = null;
  
  //Lprestador = [{ id: null, nombre: null }]

    constructor(
      private prestadorService: PrestadorService, 
      private changeDetection: ChangeDetectorRef,
      private renderer: Renderer2,
      private el: ElementRef,
      private routes: RouterModule,
      private appPlanes: AppPlanes
      ) 
      {}
    
  ngOnInit() {
    //this.recuperarTodos('');
    this.listaPrestadores('5');  
  }

  listaPrestadores(zona: string) { 
    //console.log('zona : '+zona); 
    this.Lprestadores = [];
    this.prestadorService.listaPrestadores(zona).subscribe(result => { this.Lprestadores = result; });
  }

  filtroPrestador(el) {
    $('#prestadores').val('');
    let prestadores = '';

    let checkPrestadores = '';
    $('#PrestadoresList input[type=checkbox]').each(function(a: any, b: any){
			let idcheck = '#'+b.id;
      let idlabel = '';
      if ($(idcheck).prop('checked') == true) { idlabel = idcheck.replace('#prestador-','#prestadorkey-'); checkPrestadores += $(idlabel).html() + ','; }
      
		});
    //console.log('prestadores : '+checkPrestadores);
    $('#prestadores').val('P--' + checkPrestadores.slice(0, -1));
    if ($('#prestadores').val() == 'P--') { $('#prestadores').val(''); }
    //console.log('prestadores : '+$('#prestadores').val());



    
    this.appPlanes.SendFilterPlanes('');
  }

  recuperarTodos(param) {
    //console.log('zonas : '+param);
    //this.prestadorService.recuperarTodos(param).subscribe(result => {this.Lprestadores = result; });
  }

  getPrestadores(zonas) {

    //console.log('getPrestadores : '+zonas);

		this.recuperarTodos(zonas);
			//this.prestadorService.recuperarTodos(zonas).subscribe( 
			//	(data: any[]) => {
		//			this.Lprestadores = data;
			//	 }, 
		//			error => console.log('getPrestadores params : '+zonas+' Error : '+error) 
		//		);


  }
 
  hayRegistros() {
    return true;
  } 

}
