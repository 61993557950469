import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  url='https://apivendor.tu7.cl/';

  constructor(private http: HttpClient) { }

  sendMailEjecutivo(nombreInput,rutInput,actualInput,mailInput,telefonoInput,comentarioInput,beneficiarios,theplans,ufValue,vistaPLanes) {

    //console.log('nombreInput : '+nombreInput);
    //console.log('rutInput : '+rutInput);
    //console.log('rutInput : '+rutInput);
    //console.log('actualInput : '+actualInput);
    //console.log('mailInput : '+mailInput);
    //console.log('telefonoInput : '+telefonoInput);
    //console.log('comentarioInput : '+comentarioInput);
    //console.log(beneficiarios);
    //console.log('theplans : '+theplans);   
    
    //console.log(this.url + 'sendMail.php?params=S|'+nombreInput+'|'+rutInput+'|'+actualInput+'|'+mailInput+'|'+telefonoInput+'|'+comentarioInput+'|'+beneficiarios+'|'+theplans+'|'+ufValue);
    return this.http.get(this.url + 'sendMail.php?params=S|'+nombreInput+'|'+rutInput+'|'+actualInput+'|'+mailInput+'|'+telefonoInput+'|'+comentarioInput+'|'+beneficiarios+'|'+theplans+'|'+ufValue+'|'+vistaPLanes);
    //return this.http.post(this.url + 'sendMail.php?params=S|'+nombreInput);

    //let Params = new HttpParams();
    //Params = Params.append('params', 'S|'+nombreInput+'|'+rutInput+'|'+actualInput+'|'+mailInput+'|'+telefonoInput+'|'+comentarioInput+'|'+beneficiarios+'|'+theplans+'|'+ufValue);
    //return this.http.post( this.url + 'sendMail.php',{'params' : Params} );


  }

  sendMailEjecutivoContact(nombreInput,rutInput,actualInput,mailInput,telefonoInput,comentarioInput) {
    //console.log(this.url + 'sendMailContact.php?params=S|'+nombreInput+'|'+rutInput+'|'+actualInput+'|'+mailInput+'|'+telefonoInput+'|'+comentarioInput);
    return this.http.get(this.url + 'sendMailContact.php?params=S|'+nombreInput+'|'+rutInput+'|'+actualInput+'|'+mailInput+'|'+telefonoInput+'|'+comentarioInput);
  }

  getReferidoData(nombreInput,rutInput,actualInput,mailInput,telefonoInput,comentarioInput,codigosPLanes) {
    //console.log(this.url + 'getData.php?nombreInput='+nombreInput+'&rutInput='+rutInput+'&actualInput='+actualInput+'&mailInput='+mailInput+'&telefonoInput='+telefonoInput+'&comentarioInput='+comentarioInput+'&codigosPLanes='+codigosPLanes);
    return this.http.get(this.url + 'getData.php?nombreInput='+nombreInput+'&rutInput='+rutInput+'&actualInput='+actualInput+'&mailInput='+mailInput+'&telefonoInput='+telefonoInput+'&comentarioInput='+comentarioInput+'&codigosPLanes='+codigosPLanes);
  }

}

// creame un modelo de datos en mysql para almacenar los click que hace un usuario en un botón de un sitio web?
