import { Component, OnInit, ElementRef } from '@angular/core'; 
import { IsapresService } from '../isapres.service';
import { AppPlanes } from './app.planes';

//import { HttpClient } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'filter-isapres',
  templateUrl: './filter.isapres.html'
})

export class FilterIsapres { 

  /*
  Isapres = [ 
    {id: '1', nombre: 'Banmedica', planes: '111'},
    {id: '2', nombre: 'Consalud', planes: '222'},
    {id: '3', nombre: 'Colmena', planes: '333'},
    {id: '4', nombre: 'Cruz Blanca', planes: '444'},
    {id: '5', nombre: 'Vidatres', planes: '555'},
    {id: '6', nombre: 'Mas Vida', planes: '666'}
   ];
*/
   
  Solo: string = 'SOLO';

  callIsapreSolo() {
    //alert('Call callIsapreSolo!'); 
    //console.log(this.Isapres);
    //alert('Call callIsapreSolo!');  
  }

  clickIsapre() {
    console.log(this); 
  }

  Lisapres = null;
  Lisapre = [{ id: null, nombre: null, ges: null, planes: null, logo: null }] 

  //Lplanes = null;
  //Lplan = [{ id: null, codigo: null, nombre: null, base: null, isapre: null, hospitalaria: null, ambulatoria: null }]

  
    constructor(private isapresServicio: IsapresService, private el: ElementRef, public planesApp: AppPlanes) {}
  
    ngOnInit() {
      this.recuperarTodos();
    }
  
    recuperarTodos() { 
      //console.log('recuperarTodos');
      this.isapresServicio.recuperarTodos().subscribe(result => this.Lisapres = result);
    }

    hayRegistros() {
      return true;
    } 

    showsoloisapre(el) {
      let Tagshow = document.getElementById('showSoloisapre-'+el);
      let Taghide = document.getElementById('showplanesisapresolo-'+el);
      Taghide.classList.add('hidden');
      Tagshow.classList.remove('hidden');
    }

    showtodoisapre(el) {
      let Tagshow = document.getElementById('showTodoisapre-'+el);
      let Taghide = document.getElementById('showplanesisapretodo-'+el);
      Taghide.classList.add('hidden');
      Tagshow.classList.remove('hidden');
    }

    showplanessoloisapre(el) {
      let Tagshow = document.getElementById('showplanesisapresolo-'+el);
      let Taghide = document.getElementById('showSoloisapre-'+el);
      Taghide.classList.add('hidden');
      Tagshow.classList.remove('hidden');
    }

    showplanestodoisapre(el) {
      let Tagshow = document.getElementById('showplanesisapretodo-'+el);
      let Taghide = document.getElementById('showTodoisapre-'+el);
      Taghide.classList.add('hidden');
      Tagshow.classList.remove('hidden');
    }




/* 
    showSoloIsapre(el) {
      let Tagshow = document.getElementById('showSoloIsapre-'+el);
      let Taghide = document.getElementById('showPlanesIsapre-'+el);
      Taghide.classList.add('hidden');
      Tagshow.classList.remove('hidden');
    }

    showPlanIsapre(el) {
      let Tagshow = document.getElementById('showPlanesIsapre-'+el);
      let Taghide = document.getElementById('showSoloIsapre-'+el);
      Taghide.classList.add('hidden');
      Tagshow.classList.remove('hidden');
    }

*/    

filtroIsapres() {
      this.planesApp.SendFilterPlanes('');
      return false;
      //var filtroCheck = 'I-'; 
      /*
      var filtroCheck = '';

      [].forEach.call(
        document.querySelectorAll('input, checkbox'), 
        function(e) { 
          var str = e;
          //console.log('str : '+str.type);
          if (str.type == 'checkbox') {
            var n = str.id.indexOf("isapre-");
            if (n == 0) {
              //console.log('id : '+str.id+' - checked : '+str.checked);
              if (str.checked == true) {
                var checkId = str.id;
                var porciones = checkId.split('-');
                filtroCheck = filtroCheck + porciones[1] + ',';
              }
            }  
          }
        }
      );
      if (filtroCheck !== '') {
         filtroCheck = filtroCheck.slice(0, -1)
         filtroCheck = 'I-'+filtroCheck;
      } else {
         filtroCheck = 'A';
      }
      //console.log('filtroCheck : '+filtroCheck);
      this.planesApp.SendFilterPlanes(filtroCheck);
       */
    }

  


}
