import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { FactoresService } from '../factores.service';
import { UfService } from '../uf.service';
import { AppPlanes } from './app.planes';

declare var $: any;

@Component({
  selector: 'filter-people',
  templateUrl: './filter.people.html'
})

//primero > 18 siempre cotizante
//< 18 siempre carga
//cambiar texto check por cotizante on y off

export class FilterPeople {
  title = 'filter-people'; 
  
  public theFactor;
  public personTipoSave = ''; 
  public personId = 0;
  public sumaFactores = '0.00';  
  public valorUF = null;
  public Cotizantes = 0;
  public enviaFactores = '';

  //myObjArray = [{ id: null, edad: null, factor: null, tipo: null }];
  
  myObjArray = [];

  //myObjArray: any[] = [
    //{ id: null, edad: null, factor: null, tipo: null } 
  //];

  persons: any[] = [
    //{ id: null, edad: null, factor: null, tipo: null }  
  ];

  Lfactores = null;
  Lfactor = [{ id: null, desde: null, hasta: null, cotizante: null, carga: null }] 
  
  constructor(private factoresServicio: FactoresService, private ufServicio: UfService, private el: ElementRef, public planesApp: AppPlanes) {}
  
  ngOnInit() {
    //this.planesApp.recuperarUF();  
    this.recuperarUF();
  }
  
  recuperarUF() {
    this.ufServicio.recuperarUF().subscribe(result => { this.valorUF = result; $("#UFvalor").val(result); }, error => console.log('error!!! : '+error) );
    //console.log('Listo!');
  }

  hayRegistros() {
    return true;
  } 

  @ViewChild("personEdad") personEdad: ElementRef;
  @ViewChild("personTipo") personTipo: ElementRef;

  financial(x) {
    //console.log(Number.parseFloat(x).toFixed(2));
    return Number.parseFloat(x).toFixed(2);  
  }
  public result = 0;

  sumTheFactor(arrayval) {
    this.result = 0;

    for(let o of arrayval) {
      this.result += Number(o.factor);
      //console.log(o.factor);
    }
    return this.financial(this.result).toString();
  }

  tipoChange() {
      if (this.personTipo.nativeElement.checked == false) { 
        $('#labelswitch').html('Cotizante');
      } else {
        $('#labelswitch').html('Carga');
      }
  }

  filtroPerson() {  
    this.planesApp.msgClose();
    this.enviaFactores = '';
    //if (this.personEdad.nativeElement.value == 'edad') { this.personEdad.nativeElement.value = 0; }
    let personEdadVal = this.personEdad.nativeElement.value;
    let personTipoVal = this.personTipo.nativeElement.checked;

    if (personEdadVal < 18) { 
      this.personTipoSave = 'Carga'; 
      personTipoVal = true; 
      $('#labelswitch').html('Carga'); 
      this.personTipo.nativeElement.checked = true; 
    }
    if (personEdadVal >= 18 && this.Cotizantes == 0 ) { 
      this.personTipoSave = 'Cotizante'; 
      personTipoVal = false; 
      this.personTipo.nativeElement.checked = false; 
      $('#labelswitch').html('Cotizante'); 
    }

     if (personTipoVal !== true && this.Cotizantes !== 2) {
        this.personTipoSave = 'Cotizante';
        this.Cotizantes = this.Cotizantes + 1;
        //console.log(this.Cotizantes);
     } else {
        this.personTipoSave = 'Carga';
     }
    //console.log(personEdadVal);
    //console.log(personTipoVal);
    let countVal = this.personId++;
    $('#ordena').val('0');

    if (this.personTipoSave === 'Cotizante') {
      this.factoresServicio.recuperarTodos(personEdadVal).subscribe(result =>   
        { 
          this.theFactor = result;
          this.myObjArray.push({
            id: countVal.toString(),
            edad: personEdadVal,
            factor: this.theFactor[0].cotizante, 
            tipo: this.personTipoSave
          }); this.result += Number(this.theFactor[0].cotizante); this.sumaFactores = this.financial(this.result).toString();
          this.enviaFactores = this.sumaFactores;
          this.myObjArray.sort((a,b) => b.tipo.localeCompare(a.tipo));
          //this.planesApp.SendFilterPlanes(this.enviaFactores);
          this.planesApp.SendFilterFactores(this.enviaFactores,this.myObjArray.length);
        },
         //error => { alert("Error en la petición"); }
      );
    } else {
      this.factoresServicio.recuperarTodos(personEdadVal).subscribe(result => 
        { 
          this.theFactor = result; 
          this.myObjArray.push({   
            id: countVal.toString(),
            edad: personEdadVal,
            factor: this.theFactor[0].carga, 
            tipo: this.personTipoSave
          }); this.result += Number(this.theFactor[0].carga); this.sumaFactores = this.financial(this.result).toString();
          this.enviaFactores = this.sumaFactores;
          this.myObjArray.sort((a,b) => b.tipo.localeCompare(a.tipo));
          this.planesApp.SendFilterFactores(this.enviaFactores,this.myObjArray.length);
          //this.planesApp.SendFilterPlanes(this.enviaFactores);
        },
         //error => { alert("Error en la petición"); }
      );
      
    }

// ORDENAR POR COTIZANTE

    //console.log('enviaFactores : '+this.enviaFactores);
    //this.planesApp.SendFilterPlanes(this.sumaFactores);

    //console.log(this.myObjArray);
    //this.sumaFactores = this.sumTheFactor(this.myObjArray);
    //this.sumaFactores = this.financial(this.result).toString();
    //this.sumTheFactor(this.myObjArray);
    this.personEdad.nativeElement.value = '';
  }

  deletePerson(evt) {
    //console.log(evt.id);
    let objName = evt.id;
    let lineSeparate = objName.split('-');
    let lineId = lineSeparate[1];
    //console.log(lineId);
    //console.log(this.myObjArray);
    for(let o of this.myObjArray) {
      if (o.id == lineId && o.tipo == 'Cotizante') {
        this.Cotizantes = this.Cotizantes - 1;
      }
    }
    this.myObjArray = this.myObjArray.filter(item => item.id !== lineId );
    //console.log('myObjArray : '+this.myObjArray.length);


    for(let o of this.myObjArray) {
      if (o.id == lineId) {
        //console.log(o.id);
        //console.log(this);
        this.myObjArray.splice(o.id, 1);
        //console.log(this.myObjArray);
      }
    }
    this.sumaFactores = this.sumTheFactor(this.myObjArray);
    this.planesApp.SendFilterFactores(this.sumaFactores,this.myObjArray.length);
  }

}

